import React, { useState, useEffect } from 'react';

const CountDownTimer = () => {
  const [timer, setTimer] = useState(900); // 15 minutes in seconds

  useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => (prevTimer === 0 ? 900 : prevTimer - 1));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  return <span style={{color: '#f87c7c'}}>{formatTime(timer)}</span>;
};

export default CountDownTimer;

