import React from 'react'

const SectionTitle = (props) => {
    return (
        <div className="row">
            <div className="col-12">
                <div className="wpo-section-title">
                    {
                        props.subTitle !== "" &&
                        <span>{props.subTitle}</span>
                    }
                    {
                        props.Title !== "" &&
                        <h2>{props.Title}</h2>
                    }
                </div>
            </div>
        </div>
    )
}

export default SectionTitle;