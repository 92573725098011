import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
// import Accordion from '@material-ui/core/Accordion';
// import AccordionSummary from '@material-ui/core/AccordionSummary';
// import AccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
// import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { styled } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },

}));

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        //   expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const FaqSection = (props) => {
    
    const data = [
        {
            qus: "Who can write the IBBI registered Valuer exam?",
            ans: "The IBBI registered Valuer exam can be taken by individuals with a graduate or post-graduate degree in specified disciplines like engineering, finance, or law, and a minimum of three years of relevant experience. Candidates must also be members of a recognized Registered Valuers Organisation (RVO) and complete a 50-hour educational course by the RVO. They must be at least 18 years old and meet the IBBI's fit and proper person criteria. Successful candidates receive a certificate of registration as a valuer."
        },
        {
            qus: "Who conducts IBBI Registered Valuer Examination",
            ans: "The IBBI Registered Valuer Examination is conducted by the Insolvency and Bankruptcy Board of India (IBBI) through NISM Portal. IBBI is the regulatory authority responsible for overseeing the exam. The examination assesses candidates' knowledge in valuation principles, legal regulations, methodologies, and professional ethics. The exam is held at various designated centers across India. Successful candidates receive a certificate and eligible for membership."
        },
        {
            qus: "What are all the opportunities for IBBI registered valuer ?",
            ans: "IBBI registered valuers have opportunities in insolvency and bankruptcy proceedings, mergers and acquisitions (M&A), and financial reporting. They can assist in asset valuation for taxation purposes and provide litigation support in legal disputes. Valuers can also work in real estate valuation, assessing residential, commercial, and industrial properties. Additionally, they offer advisory services for strategic planning and business restructuring. Investment firms and fund managers benefit from their expertise in valuing portfolios and assets. Lastly, they can engage in valuations for government and public sector projects."
        },
        {
            qus: "Can an IBBI Registered Valuer do valuation of all the assets.",
            ans: "No, an IBBI Registered Valuer cannot do the valuation of all assets. They are authorized to value only the assets within their specific discipline, such as Land and Building, Plant and Machinery, or Securities and Financial Assets. Each valuer must be registered and qualified in the particular asset class they intend to value. The IBBI ensures that valuers possess the necessary expertise and qualifications for their specific category. Valuers must adhere to the standards and regulations applicable to their asset class. Hence, cross-discipline valuations require appropriate qualifications and registration."
        }
    ];

    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div className="wpo-benefits-section">
            <div className="row">
                <div className="col-lg-12 col-12">
                    <div className="wpo-benefits-item">
                        {
                            data && data.length > 0 && data.map((item, index) => (
                                <Accordion key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel${index}bh-content`}
                                        id={`panel${index}bh-header`}
                                    >
                                        <Typography className={classes.heading}>{item?.qus}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography>
                                            {item?.ans}
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FaqSection;