import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom'
import Campaign from '../../api/campaign'
import shape from '../../images/campaign/shape.png'
import SectionTitle from '../SectionTitle/SectionTitle';

const settings = {
    dots: false,
    arrows: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
};

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const CampaignSectionS2 = (props) => {
    return (
        <div className="pt-4">
            <SectionTitle subTitle={'Who Should Attend'} Title={'The Webinar Would Be Beneficial For'} />
            <div className={`wpo-campaign-area-s2 ${props.cmClass} mt-0 mb-5`}>
                <div className="container">
                    <div className="wpo-campaign-wrap wpo-campaign-active owl-carousel">
                        <Slider {...settings}>
                            {Campaign.map((campaign, srv) => (
                                <div className="wpo-campaign-single" key={srv}>
                                    <div className="wpo-campaign-item">
                                        <div className="wpo-campaign-img">
                                            <img src={campaign.cImg2} alt="" />
                                        </div>
                                        <div className="wpo-campaign-content">
                                            <div className="wpo-campaign-text-top">
                                                <small>{campaign.thumb}</small>
                                                <h2><Link onClick={ClickHandler} to={`/campaign-single/${campaign.slug}`}>{campaign.title}</Link></h2>
                                                {/* <p><i className="fi flaticon-time"></i> {campaign.date} @ {campaign.time}</p>
                                            <p><i className="fi flaticon-pin"></i> {campaign.location}</p> */}
                                                <div className="details">
                                                    <p>{campaign.desp}</p>
                                                </div>
                                                <div className="progress-section">
                                                    Industry Impact Score
                                                    <div className="process">
                                                        <div className="progress">
                                                            <div className="progress-bar" style={{ width: `${campaign.progress}%` }}>
                                                                <div className="progress-value"><span>{campaign.progress}</span>%</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <ul>
                                                <li><span>Raised:</span> ${campaign.raised}</li>
                                                <li><span>Goal:</span> ${campaign.goal}</li>
                                            </ul> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
                <div className="shape-left">
                    <img src={shape} alt="" />
                </div>
                <div className="shape-right">
                    <img src={shape} alt="" />
                </div>
            </div>
        </div>
    );
}

export default CampaignSectionS2;