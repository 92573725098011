import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/logo.svg'
import Services from '../../api/service';
import Stack from '@mui/material/Grid';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import styled from "styled-components";
import Button from '@mui/material/Button';
import CountDownTimer from '../CountDownTimer/CountDownTimer';
import { useTheme } from '@mui/material/styles';

const ClickHandler = () => {
    window.scrollTo(10, 0);
}
const SubmitHandler = (e) => {
    e.preventDefault()
}

const MyButton = styled(({ color, ...other }) => <Button {...other} />)((props) => ({
    background:
        props.color === 'red'
            ? 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'
            : 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    border: 0,
    borderRadius: '12px !important',
    boxShadow:
        props.color === 'red'
            ? '0 3px 5px 2px rgba(255, 105, 135, .3)'
            : '0 3px 5px 2px rgba(33, 203, 243, .3)',
    color: 'white !important',
    height: 48,
    padding: '16px 20px 16px 20px !important',
    lineHeight: '1 !important',
    // margin: 8,
    // fontFamily: '"Montserrat", Sans-serif !important',
    fontSize: '16px !important',
    fontWeight: '600 !important',
    textTransform: 'uppercase !important',
    animation: 'anime 5.5s linear infinite',
    width: "100%",
    '@keyframes anime': {
        '0%': { transform: 'scale(1)', boxShadow: '0 0 20px 6px rgba(33, 150, 243, .3)' },
        '50%': { transform: 'scale(1.05)', boxShadow: '0 0 20px 6px rgba(33, 150, 243, .3)' },
        '100%': { transform: 'scale(1)', boxShadow: '0 0 20px 6px rgba(33, 150, 243, .3)' },
    },
    [props.theme.breakpoints.down('sm')]: {
        fontSize: '10px !important',
        padding: '12px 16px 12px 16px !important',
        fontWeight: '600 !important',
    },
    [props.theme.breakpoints.down('md')]: {
        fontSize: '12px !important',
        padding: '12px 16px 12px 16px !important',
        fontWeight: '600 !important',
    },
    // '@media (max-width: 768px)': {
    //     fontSize: '12px !important',
    //     padding: '12px 16px 12px 16px !important',
    //     height: 48,
    //     // width: '160px',
    //     fontWeight: '600 !important',
    // },
    // '@media (max-width: 430px)': {
    //     fontSize: '12px !important',
    //     padding: '12px 16px 12px 16px !important',
    //     height: 48,
    //     width: '160px',
    //     fontWeight: '600 !important',
    //     wordWrap: 'break-word !important'
    // },
}));

const footerStyles = {
    bottom: 0,
    width: '100%',
    left: 0,
    // backgroundColor: 'rgba(0, 0, 0, 0.5)',
    // marginTop: "16px"
    padding: "10px"

};

const PriceText = styled.span`
    color: #FFA756;
    // background: linear-gradient(109.6deg, rgb(255, 194, 48) 11.2%, rgb(255, 124, 0) 100.2%);
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: transparent;
    // font-family: Montserrat, sans-serif;
    font-size: 25px;
    font-weight: 700;
    line-height: 1.4em;
    @media (max-width: 768px) {
        font-size: 20px !important;
    }
`;

const StrikeText = styled.span`
    padding-left: 10px;
    // color: #2B2B2B;
    color: #fff;
    // font-family: "Montserrat", Sans-serif;
    font-size: 25px;
    font-weight: 600;
    text-decoration: line-through;
    text-decoration-color: red;
    text-decoration-thickness: 3px;
    line-height: 1.4em;
    @media (max-width: 768px) {
        font-size: 14px !important;
    }
`;

const LimitedText = styled.div`
    // color: #101010;
    color: #fff;
    // font-family: "Montserrat", Sans-serif;
    font-size: 17px;
    font-weight: 600;
    white-space: nowrap;
    @media (max-width: 768px) {
        font-size: 12px !important;
    }
`;

const Footer = (props) => {
    const theme = useTheme();

    const handleSubmit = (e) => {
        e.preventDefault();

        var options = {
            key: "rzp_live_cYZydtfWzO3MrZ",
            key_secret: "axVI9xOAWDCNGiamNhHXxysg",
            amount: 99*100,
            currency: "INR",
            name: "Re10x",
            description: "Become a Registered Valuer",
            handler: function (response) {
                // alert(response.razorpay_payment_id);
                console.log("response.razorpay_payment_id")
                console.log(response.razorpay_payment_id)
                console.log("response")
                console.log(response)
            },
            // prefill: {
            //     name: "Shyamsundar",
            //     email: "shyam2785@gmail.com",
            //     contact: "9884730355"
            // },
            notes: {
                address: "Re10x.in Corporate office"
            },
            modal: {
                ondismiss: function() {
                    console.log("Checkout form closed");
                }
            },
            // theme: {
            //     color: "linear-gradient(90deg, #ED6B37 11.32%, #EC180C 95.28%)"
            // }
        };
        var pay = new window.Razorpay(options);
        pay.on("payment.failed", function (response) {
            console.log("Payment failed response")
            console.log(response)
            // alert(response.error.code);
            // alert(response.error.description);
            // alert(response.error.source);
            // alert(response.error.step);
            // alert(response.error.reason);
            // alert(response.error.metadata.order_id);
            // alert(response.error.metadata.payment_id);
          });
        pay.open();
    };

    return (
        <div className="container-fluid" style={{ padding: 0, position: "fixed", bottom: 0, zIndex: 999 }}>
            <footer className="wpo-site-footer">
                {/* <div className="wpo-upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-3 col-md-6 col-12 col-md-6 col-sm-12 col-12">
                            <div className="widget about-widget">
                                <div className="logo widget-title">
                                    <Link onClick={ClickHandler} className="navbar-brand" to="/index"><img src={Logo} alt="" /></Link>
                                </div>
                                <ul className="info">
                                    <li>Phone: 111-222-333-2155</li>
                                    <li>Email: contact@mail.com</li>
                                    <li>Address: 22/1 Melborane city austria,
                                        human resoerch, Canada</li>
                                </ul>
                                <div className="social">
                                    <ul>
                                        <li>
                                            <Link onClick={ClickHandler} to="/">
                                                <i className="fi flaticon-facebook-app-symbol"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={ClickHandler} to="/">
                                                <i className="fi flaticon-twitter"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={ClickHandler} to="/">
                                                <i className="fi flaticon-linkedin"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={ClickHandler} to="/">
                                                <i className="fi flaticon-instagram-1"></i>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link onClick={ClickHandler} to="/">
                                                <i className="fi flaticon-pinterest"></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-12 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Quick Links</h3>
                                </div>
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/home">Home</Link></li>
                                    <li><Link onClick={ClickHandler} to="/about">About Us</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service">Mission & Vision</Link></li>
                                    <li><Link onClick={ClickHandler} to="/team">Team</Link></li>
                                    <li><Link onClick={ClickHandler} to="/campaign">Campaign</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-12 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget s2">
                                <div className="widget-title">
                                    <h3>Mission & Vision</h3>
                                </div>
                                <ul>
                                    {Services.slice(0, 5).map((service, Sitem) => (
                                        <li key={Sitem}><Link onClick={ClickHandler} to={`/service-single/${service.slug}`}>{service.sTitle}</Link></li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-12 col-md-6 col-sm-12 col-12">
                            <div className="widget newsletter-widget">
                                <div className="widget-title">
                                    <h3>Subscribe Newsletter</h3>
                                </div>
                                <p>Get timely updates ! We only send interesting and relevant emails.</p>
                                <form onSubmit={SubmitHandler}>
                                    <div className="input-1">
                                        <input type="email" className="form-control" placeholder="Your Email Address..."
                                            required="" />
                                    </div>
                                    <div className="submit clearfix">
                                        <button type="submit">Subscribe now</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
                {/* <div className="wpo-lower-footer">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col col-lg-6 col-md-6 col-6">
                            <ul>
                                <li>&copy; 2023 <Link onClick={ClickHandler} to="/">Politian</Link>. All rights reserved.</li>
                            </ul>
                        </div>
                        <div className="col col-lg-6 col-md-6 col-6">
                            <div className="link">
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/privacy">Privace & Policy</Link></li>
                                    <li><Link onClick={ClickHandler} to="/terms">Terms</Link></li>
                                    <li><Link onClick={ClickHandler} to="/about">About us</Link></li>
                                    <li><Link onClick={ClickHandler} to="/faq">FAQ</Link></li>
                                    <li><Link onClick={ClickHandler} to="/volunteer">Volunteer</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
                <div className="container">
                    <Stack sx={footerStyles}>
                        <Grid
                            spacing={2}
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                        >
                            <Grid item xs={6}>
                                <Box>
                                    <Stack spacing={{ xs: 0, sm: 0, md: 2 }} direction="row" alignItems="center">
                                        <PriceText>₹99/-</PriceText>
                                        <StrikeText>₹999</StrikeText>
                                        <LimitedText>
                                            Offer Ends in Mins <CountDownTimer />
                                        </LimitedText>
                                    </Stack>
                                </Box>
                            </Grid>
                            <Grid item lg={5} md={5} sm={5} xs={6} >
                                <MyButton color="blue" theme={theme} onClick={handleSubmit}>
                                    Book Your Spot Now At ₹99/-
                                </MyButton>
                            </Grid>
                        </Grid>
                    </Stack>
                </div>
            </footer>
        </div>
    )
}

export default Footer;