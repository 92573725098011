import React from 'react'
import sign from '../../images/signeture.png'
import { Link } from 'react-router-dom';

const About = (props) => {
    return (
        <section className={`wpo-about-section section-padding ${props.abClass}`}>
            <div className="container">
                <div className="wpo-about-wrap">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-img">
                                <img src={props.abimg} alt="" />
                                <div className="wpo-about-img-text">
                                    <h4>1998</h4>

                                    <div className="rotate-text">
                                        <span>W</span>
                                        <span>e</span>
                                        <span>A</span>
                                        <span>r</span>
                                        <span>e</span>
                                        <span>W</span>
                                        <span>o</span>
                                        <span>r</span>
                                        <span>k</span>
                                        <span>i</span>
                                        <span>n</span>
                                        <span>g</span>
                                        <span>F</span>
                                        <span>o</span>
                                        <span>r</span>
                                        <span>Y</span>
                                        <span>o</span>
                                        <span>u</span>
                                        <span>S</span>
                                        <span>i</span>
                                        <span>n</span>
                                        <span>c</span>
                                        <span>e</span>
                                    </div>
                                    <div className="dots">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                    <div className="border-shape-1"></div>
                                    <div className="border-shape-2"></div>
                                    <div className="border-shape-3"></div>
                                </div>
                                <div className="about-shape">
                                    <div className="shape-1"></div>
                                    <div className="shape-2"></div>
                                    <div className="shape-3"></div>
                                    <div className="shape-4"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-text">
                                <div className="wpo-section-title">
                                    <span>About Your Coach</span>
                                    <h2>We Can Work Together To Create a Better Future.</h2>
                                </div>
                                <p> Meet Mr.Sriram, He bring over a decade of dedicated experience 
                                    in this dynamic field. His extensive background in valuation, combined with his roles as a practicing company secretary 
                                    and lawyer, has provided him with a deep understanding of the legal and regulatory frameworks that govern valuation processes. 
                                    This unique blend of expertise enables him to deliver robust and reliable valuations across various contexts.
                                </p>
                                <p>During this session, you will be share with practical strategies, key insights, and real-world examples from his professional journey. 
                                    Whether you're a seasoned professional looking to refine your valuation techniques or a newcomer eager to learn the fundamentals, 
                                    we are confident that this discussion will be both enlightening and enriching.</p>
                                <div className="quote">
                                    <p>“We can start by taking small steps and making small changes that can have a big
                                        impact on the world.”</p>
                                </div>
                                <div className="wpo-about-left-info">
                                    <div className="wpo-about-left-inner">
                                        <div className="wpo-about-left-text">
                                            <h5>Mr. Sriram K</h5>
                                            <span>Registered Valuer – SFA<br></br></span>
                                            <span>IBBI-Insolvency Professional<br></br></span>
                                            <span>Practicing Company Secretary</span>
                                            {/* <span>CEO & Founder of <Link>TruzlyIndia.com</Link></span> */}
                                        </div>
                                    </div>
                                    {/* <div className="signeture">
                                        <img src={sign} alt="" />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;