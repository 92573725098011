import React from "react";
import { css } from '@emotion/react';
import styled from "styled-components";

const NavContainer = styled.div`
   --e-icon-list-icon-size: 23px;
    --icon-vertical-offset: 0px;
    width: 100%;
    max-width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: flex;
    justify-content: center; /* Centers items horizontally */
    align-items: center; /* Centers items vertically */
`;

const WidgetContainer = styled.div`
    margin: 0px 0px 22px 0px;
    padding: 10px 20px 12px 20px;
    background: linear-gradient(286.02deg, #080A14 38.02%, #10121E 61.92%, #282A34 74.32%, #404354 90.71%, #343643 99.65%);
    border-radius: 0px 0px 20px 20px;
    box-shadow: 0px 4px 20px 0px rgba(11, 102, 101, 0.25);
    color: #fff;
    font-size: 17px;
    font-weight: 600;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 10px;
`;

export default function Navbar3() {

    return (
        <div className="container">
            <NavContainer>
                <WidgetContainer>
                    <div>
                        <svg width="40px" height="40px" viewBox="-2.4 -2.4 28.80 28.80" 
                            fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff" 
                            stroke-width="0.00024000000000000003">
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.048"></g>
                            <g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 17.1086 6.89137 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C6.06294 22.75 1.25 17.9371 1.25 12ZM12 7.25C12.4142 7.25 12.75 7.58579 12.75 8V11.6893L15.0303 13.9697C15.3232 14.2626 15.3232 14.7374 15.0303 15.0303C14.7374 15.3232 14.2626 15.3232 13.9697 15.0303L11.4697 12.5303C11.329 12.3897 11.25 12.1989 11.25 12V8C11.25 7.58579 11.5858 7.25 12 7.25Z" fill="#f5f7ff">
                            </path> 
                            </g>
                        </svg>
                    </div>
                    3 HOURS LIVE WORKSHOP 
                </WidgetContainer>
            </NavContainer>
        </div>
    );
}
