import React from 'react';
import { Link } from 'react-router-dom';
import CountDownCircleTimer from '../CountDownCircleTimer/CountDownCircleTimer';

const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const Donors = (props) => {

    const handleSubmit = (e) => {
        e.preventDefault();

        var options = {
            key: "rzp_live_cYZydtfWzO3MrZ",
            key_secret: "axVI9xOAWDCNGiamNhHXxysg",
            amount: 99*100,
            currency: "INR",
            name: "Re10x",
            description: "Become a Registered Valuer",
            handler: function (response) {
                // alert(response.razorpay_payment_id);
                console.log("response.razorpay_payment_id")
                console.log(response.razorpay_payment_id)
                console.log("response")
                console.log(response)
            },
            // prefill: {
            //     name: "Shyamsundar",
            //     email: "shyam2785@gmail.com",
            //     contact: "9884730355"
            // },
            notes: {
                address: "Re10x.in Corporate office"
            },
            modal: {
                ondismiss: function() {
                    console.log("Checkout form closed");
                }
            },
            // theme: {
            //     color: "linear-gradient(90deg, #ED6B37 11.32%, #EC180C 95.28%)"
            // }
        };
        var pay = new window.Razorpay(options);
        pay.on("payment.failed", function (response) {
            console.log("Payment failed response")
            console.log(response)
            // alert(response.error.code);
            // alert(response.error.description);
            // alert(response.error.source);
            // alert(response.error.step);
            // alert(response.error.reason);
            // alert(response.error.metadata.order_id);
            // alert(response.error.metadata.payment_id);
          });
        pay.open();
    };

    return (
        <section className={`wpo-donors-section ${props.prClass}`}>
            <div className="container">
                <div className="wpo-donors-wrap">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <h2>14th Sep 2024 (Saturday)</h2>
                            <h3 style={{color: "#fff"}}>(10:00 AM to 1:00 pm)</h3>
                            <h3 style={{color: "#fff"}}>Language - Basic English </h3>
                            <div className="donors-btn mt-4">
                                <Link onClick={handleSubmit} to="/">Register now for just ₹ 99/- only</Link>
                                {/* <Link onClick={ClickHandler} to="/">Connect with me</Link> */}
                            </div>
                        </div>
                        <div className="col-lg-6">
                             <div className="donors-btn">
                                {/* <Link onClick={ClickHandler} to="/">Other</Link> */}
                                {/* <Link onClick={ClickHandler} to="/">Register now for just 99/- only</Link> */}
                                <CountDownCircleTimer date={"14/09/2024"} time ={'10am'}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Donors;