import cimg1 from '../images/campaign/img-1.jpg'
import cimg2 from '../images/campaign/img-2.jpg'
import cimg3 from '../images/campaign/img-3.jpg'

import cS2img1 from '../images/campaign/commerce.jpg'
import cS2img2 from '../images/campaign/civil.jpg'
import cS2img3 from '../images/campaign/charted.jpg'
import cS2img4 from '../images/campaign/account.jpg'
import cS2img5 from '../images/campaign/law.jpg'

import cSimg1 from '../images/campaign/single/img-1.jpg'
import cSimg2 from '../images/campaign/single/img-2.jpg'
import cSimg3 from '../images/campaign/single/img-3.jpg'



const Campaign = [
    {
       Id:'1',
       cImg:cimg1,
       cImg2:cS2img1,
       cSimg:cSimg1,
       thumb:'Commerce Professionals',   
       title:'Enhance Your Valuation Expertise',
       desp: "Whether you're an accountant, financial analyst, auditor, or involved in any aspect of finance, understanding valuation principles is crucial. This webinar will provide insights that can enhance your decision-making process, financial analysis, and overall understanding of asset valuation.",
       slug:'Support-for-Womans',
       date:'July 1, 2023',
       time:'15:00 - 19:00',
       location:'85 Preston Rd. Inglewood',
       progress:'90',
       raised:'2000.00',
       goal:'3000.00',
    },
    {
       Id:'2',
       cImg:cimg2,
       cImg2:cS2img2,
       cSimg:cSimg2,
       thumb:'Civil Engineers',    
       title:'Master Valuation for L&B', 
       desp: "Valuation is a critical aspect of real estate and infrastructure projects. Civil engineers or related stream of professional is eligible to become a registered valuer for Land and Building. Valuing the L and B requires detailed understating of the subject assets, in case of building including the materials, life of the assets etc., it is mandatory in certain banking transactions for valuation report from Registered valuer.",
       slug:'Business-Earning',
       date:'July 2, 2023',
       time:'10:00 - 15:00',
       location:'85 Preston Rd. Inglewood',
       progress:'80',
       raised:'3000.00',
       goal:'4000.00',
    },
    {
      Id:'3',
      cImg:cimg3,
      cImg2:cS2img3,
      cSimg:cSimg3,
      thumb:'Chartered Accountants (CAs)',   
      title:'Valuation Mastery for Chartered Accountants',  
      desp: "As financial experts, CAs play a vital role in various aspects of valuation, including financial reporting, mergers and acquisitions, taxation, and litigation support. Understanding the intricacies of valuation practices can enhance their ability to provide accurate financial advice, assess business opportunities, and navigate complex financial transactions effectively.",
      slug:'Political-Commitment',
      date:'July 5, 2023',
      time:'13:00 - 16:00',
      location:'85 Preston Rd. Inglewood',
      progress:'95',
      raised:'4000.00',
      goal:'5000.00',
   },
   {
      Id:'4',
      cImg:cimg3,
      cImg2:cS2img4,
      cSimg:cSimg3,
      thumb:'Cost Management Accountants (CMAs)',   
      title:'Valuation for CMAs',
      desp: "Valuation plays a crucial role in cost management, budgeting, and strategic decision-making within organizations. CMAs can benefit from understanding valuation principles to accurately assess the value of assets, projects, or investments, enabling them to make informed cost management decisions and optimize resource allocation effectively.",
      slug:'Political-Commitment',
      date:'July 5, 2023',
      time:'13:00 - 16:00',
      location:'85 Preston Rd. Inglewood',
      progress:'95',
      raised:'4000.00',
      goal:'5000.00',
   },
   {
      Id:'5',
      cImg:cimg3,
      cImg2:cS2img5,
      cSimg:cSimg3,
      thumb:'Lawyers',   
      title:'Valuation for Lawyers',  
      desp: "Valuation is a fundamental aspect of many legal processes, including mergers and acquisitions, corporate restructuring, bankruptcy proceedings, dispute resolution, and litigation. Lawyers need to understand valuation methodologies, standards, and best practices to effectively represent their clients' interests and provide sound legal advice.",
      slug:'Political-Commitment',
      date:'July 5, 2023',
      time:'13:00 - 16:00',
      location:'85 Preston Rd. Inglewood',
      progress:'95',
      raised:'4000.00',
      goal:'5000.00',
   }
    
]

export default Campaign;